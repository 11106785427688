<template>
  <div>
    <LSFrontNavbar @showFullImage="showFullImage" :offer="offer" />
    <vs-row>
      <vs-col offset="1" w="10">
        <div class="itemsholder">
          <LSFrontItem
            :item="item"
            :discount="discount"
            v-for="item in items"
            :key="item.id"
            @showFullImage="showFullImage"
          ></LSFrontItem>
          <LSFrontOrderButton @makeOrder="makeOrder">{{
            getOrderSum()
          }}</LSFrontOrderButton>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import axios from "axios";
import LSFrontItem from "../components/LSFrontItem.vue";
import LSFrontOrderButton from "../components/LSFrontOrderButton.vue";
import LSFrontNavbar from "../components/LSFrontNavbar.vue";
export default {
  components: { LSFrontItem, LSFrontOrderButton, LSFrontNavbar },

  data() {
    return {
      orderedItems: [],
      offer: { items: [], discount: 0, offercode: 0, spoiltime: null },
      currentOfferCode: "",
      discount: 0,
      items: [],
    };
  },
  beforeMount() {
    if (this.$route.params.code.length != 5) {
      this.$router.push("/404");
    } else {
      this.currentOfferCode = this.$route.params.code;
      this.fetchItems();
    }
  },
  methods: {
    showFullImage(item) {
      this.$emit("showFullImage", item);
    },
    openNotification(position = null, color, icon) {
      this.$vs.notification({
        icon,
        color,
        position,
        title: "Ваше замовлення пусте",
        text: "не обрано жодної позиції, додайте хоча б одну та повторіть спробу",
      });
    },
    checkOrder() {
      let canorder = false;
      this.offer.items.forEach((element) => {
        if (element.order > 0 && canorder == false) {
          canorder = true;
        }
      });
      return canorder;
    },
    async makeOrder() {
      if (this.checkOrder() == true) {
        const loading = this.$vs.loading({ type: "square", color: "success" });
        const offersRequest = await axios.post(
          process.env.VUE_APP_APIPATH+"/api/offers/offer/" +
            this.currentOfferCode,
          {
            items: this.offer.items,
          }
        );
        if (offersRequest.status == 200) {
          loading.close();
          this.$router.push("/thankyou");
        } else {
          loading.close();
          this.$router.push("/about");
        }
      } else {
        this.openNotification(
          "top-center",
          "warn",
          `<i class='bx bx-error' ></i>`
        );
      }
    },
    roundUp(num, precision) {
      precision = Math.pow(10, precision);
      return Math.ceil(num * precision) / precision;
    },
    getOrderSum() {
      let completeSum = 0;
      this.items.forEach((element) => {
        let currPrice = this.roundUp(
          element.price - (element.price / 100) * this.discount,
          2
        );
        completeSum += currPrice * element.order;
      });
      let completeReport =
        this.roundUp(completeSum, 2) + "грн.";
      return completeReport;
    },
    async fetchItems() {
      const loading = this.$vs.loading({ type: "square", color: "success" });
      const offersRequest = await axios.get(
        process.env.VUE_APP_APIPATH+"/api/offers/offer/" + this.currentOfferCode
      );

      if (offersRequest.data.offer == null) {
        loading.close();
        this.$router.push("/404");
      }

      if (offersRequest.data.offer.state == "spoiled offer") {
        loading.close();
        this.$router.push("/spoiled");
      }

      if (offersRequest.data.offer.state == "completed offer") {
        loading.close();
        this.$router.push("/thankyou");
      }

      let spoiltime = offersRequest.data.offer.spoiltime;
      this.items = offersRequest.data.offer.items;
      this.discount = offersRequest.data.offer.discount;
      this.offer = {
        items: this.items,
        discount: this.discount,
        offercode: this.currentOfferCode,
        spoiltime: spoiltime,
      };
      loading.close();
    },
    updateOrders() {
      this.offer.items.forEach((element) => {
        if (element.order > 0) this.orderedItems.push(element);
      });
    },
  },
};
</script>

<style scoped>
.itemsholder {
  width: 100%;
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
</style>