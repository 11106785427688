<template>
  <div class="orderButton" @click="makeOrder">
    <div class="orderInfoSmall noselect">СУМА ЗАМОВЛЕННЯ</div>
    <div class="orderInfo noselect"><slot></slot></div>
    <div class="orderTitle noselect">ЗАМОВИТИ</div>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  methods:{
    makeOrder(){
      this.$emit('makeOrder')
    }
  }
}
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.orderButton {
  position: relative;
  background-color: #46c93a;
  width: 100%;
  min-height: 250px;
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 18px;
  color: white;
  box-shadow: 0px 60px 70px -50px rgba(15, 245, 7, 0.575);
  transition: transform 200ms ease-out, box-shadow 400ms ease-out;
}

.orderButton:hover {
  transform: scale(1.02);
  box-shadow: 0px 60px 70px -50px rgba(22, 240, 14, 0.849);
}

.orderButton:active{
  transform: scale(0.98);
}

.orderInfoSmall{
  margin-top: 15px;
  font-size: 1.0em;
  color: rgb(127, 247, 117);
  text-align: center;
  text-shadow: 0px 1px 0px rgba(52, 175, 41, 0.89);
  opacity: 0.8
}

.orderInfo{
  font-size: 2em;
  color: rgb(127, 247, 117);
  text-align: center;
  text-shadow: 0px 1px 0px rgba(52, 175, 41, 0.89);
  opacity: 0.8
}

.orderTitle {
  position: absolute;
  font-size: 2.8em;
  font-weight: 800;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

