<template>
  <div class="center">
    <vs-dialog not-close auto-width not-padding v-model="show">
      <div class="con-image">
        <img class="fullimg" :src="getImageName()" alt="" />
      </div>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: "ls-dialog-full-image",
  props: {
    images: {
      type: Array,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show:false,
    };
  },
  watch:{
    active(){
      this.show = this.active
    },
    show(){
      this.$emit('closeFullScreen', this.show)
    }
  },
  methods: {
    getImageName() {
      if (this.images) {
        return "https://xn--80axep4eb.xn--j1amh/public/images/" + this.images[0]
      }
    },
  },
};
</script>

<style scoped lang="sass">
.con-image
    border-radius: inherit
    .fullimg
      max-width: 100%
      position: relative
      border-radius: inherit
      object-fit: cover
</style>
  