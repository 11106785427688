<template>
  <div id="app">
    <router-view
      @showFullImage="showFullImage"
    ></router-view>
    <LSDialogFullImage
      @closeFullScreen="closeFullScreen"
      :images="imagesToShow"
      :active="dialogImageShow"
    ></LSDialogFullImage>
  </div>
</template>

<script>
import LSDialogFullImage from "./components/LSDialogFullImage.vue";
export default {
  components: { LSDialogFullImage },
  metaInfo: {
    title: "Масяня TM",
    titleTemplate: "%s | Landing store 2021",
  },
  name: "App",
  data() {
    return {
      orderedItems: [],
      imagesToShow: [],
      dialogImageShow: false,
    };
  },
  methods: {
    closeFullScreen(state) {
      this.dialogImageShow = state;
    },
    showFullImage(item) {
      (this.imagesToShow = item.images), (this.dialogImageShow = true);
    },
  },
};
</script>

<style lang="sass">
*
  margin: 0px
  padding: 0px
  box-sizing: border-box
  font-family: Roboto
  outline: none
  text-transform: none
  text-decoration: none
  touch-action: pan-y !important

@font-face
  font-family: "Roboto"
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype")
@font-face
  font-family: "Roboto"
  font-weight: 700;
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype")
@font-face
  font-family: "Roboto"
  font-weight: 900;
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Black.ttf") format("truetype")
</style>
