import Main from '@/pages/Main.vue'
import Contacts from '@/pages/Contacts.vue'
import Spoiled from '@/pages/Spoiled.vue'
import NotFound from '@/pages/404.vue'
import Thanks from '@/pages/Thanks.vue'
import VueRouter from 'vue-router'

const routes = [{
    path: '/',
    component: Contacts,
}, {
    path: '/offer/:code',
    component: Main,
}, {
    path: '/about',
    component: Contacts,
}, {
    path: '/spoiled',
    component: Spoiled,
}, {
    path: '/404',
    component: NotFound,
}, {
    path: '/thankyou',
    component: Thanks,
}, {
    path: '*',
    component: NotFound,
}
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router