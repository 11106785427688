  <template>
  <div class="hidden">
    <vs-button
      class="flyingbutton"
      @click="activeSidebar = !activeSidebar"
      icon
      success
    >
      <div class="labelHolder bx-rotate-270">
        <i class="bx bx-cart-alt cartlogo"></i>
        <p>кошик</p>
      </div>
    </vs-button>
    <vs-sidebar background="#4B4B4B" fixed v-model="active" :open.sync="activeSidebar">
      <template #logo>
        <div class="headerlogo">
          <img src="@/images/logo_main_light.png" />
        </div>
        <div class="countdownholder">
          <div class="headercountdown">
            <p class="headersmalltext">
              пропозиція дійсна:
            </p>
            <p>
              {{
                hoursToLive +
                ":" +
                minutesToLive +
                ":" +
                secondsToLive
              }}
            </p>
          </div>
        </div>
      </template>

      <div class="orderitemsinfo">
        <div v-for="item in orderedItems" :key="item.id">
          <div class="item">
            <vs-row>
              <vs-col vs-type="flex" w="4">
                <vs-avatar class="itemavatar" @click="openFullSize(item)" size="60">
                  <img
                    class="image"
                    :src="getImageName(item.id)"
                    :alt="item.name"
                  />
                </vs-avatar>
              </vs-col>
              <vs-col vs-type="flex" w="8">
                <vs-row>
                  <div class="code">{{ item.code }}</div>
                  <div class="name">{{ item.name }}</div>
                </vs-row>
              </vs-col>
            </vs-row>
          </div>
          <div class="itemsum">
            <vs-row>
              <vs-col class="completesum right" vs-type="flex" w="7">{{
                getSumWODiscountString(item)
              }}</vs-col>
              <vs-col class="completesum" vs-type="flex" w="5">{{
                getCompleteSumString(item)
              }}</vs-col>
            </vs-row>
          </div>
          <div class="dividerholder">
            <hr class="dividersolid" />
          </div>
        </div>
      </div>
      <template #footer>
        <div class="stringsum">
          <vs-row>
            <vs-col>
              <vs-row>
                <div>сума замовлення:</div>
              </vs-row>
              <vs-row>
                <div class="large">{{ orderSum }}</div>
              </vs-row>
            </vs-col>
          </vs-row>
        </div>
        <div class="smalltext">
          <p>МАСЯНЯ ТМ © {{ new Date().getFullYear() }}</p>
        </div>
      </template>
    </vs-sidebar>
  </div>
</template>

<script>
export default {
  name: "LS-front-navbar",
  props: {
    offer: {
      type: Object,
    },
  },
  data() {
    return {
      showFullSizeImage: false,
      imageArray: [],
      active: "offer",
      activeSidebar: false,
      hoursToLive: 0,
      minutesToLive: 0,
      secondsToLive: 0,
    };
  },
  watch: {
    offer() {
      setInterval(() => {
        let duration = new Date(this.offer.spoiltime) - new Date();
        if (duration < 0) duration = 0;
        let seconds = Math.floor((duration / 1000) % 60);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        this.hoursToLive = hours < 10 ? "0" + hours : hours;
        this.minutesToLive = minutes < 10 ? "0" + minutes : minutes;
        this.secondsToLive = seconds < 10 ? "0" + seconds : seconds;
      }, 1000);
    },
  },
  computed: {
    orderedItems() {
      let orderArray = [];
      this.offer.items.forEach((element) => {
        if (element.order > 0) orderArray.push(element);
      });
      return orderArray;
    },
    orderSum() {
      let completeSum = 0;
      this.offer.items.forEach((element) => {
        let currPrice = this.roundUp(
          element.price - (element.price / 100) * this.offer.discount,
          2
        );
        completeSum += currPrice * element.order;
      });
      return this.roundUp(completeSum, 2) + "грн";
    },
  },
  methods: {
    roundUp(num, precision) {
      precision = Math.pow(10, precision);
      return Math.ceil(num * precision) / precision;
    },
    getOrderSum() {
      let completeSum = 0;
      this.offer.items.forEach((item) => {
        let completeSum = item.price * item.order;
        let completeSumWithDiscount = this.roundUp(
          completeSum - (completeSum / 100) * this.offer.discount,
          2
        );
        completeSum += completeSumWithDiscount;
      });
      return completeSum + "грн";
    },
    getSumWODiscountString(item) {
      if(this.offer.discount == 0) return ''
      let completeSum = item.price * item.order;
      let sumstring = this.roundUp(completeSum, 2) + "грн";
      return sumstring;
    },
    getCompleteSumString(item) {
      let completeSum = item.price * item.order;
      let completeSumWithDiscount = this.roundUp(
        completeSum - (completeSum / 100) * this.offer.discount,
        2
      );
      let sumstring = completeSumWithDiscount + "грн";
      return sumstring;
    },
    openFullSize(item) {
      this.$emit("showFullImage", item);
      this.imageArray = item.images;
      this.showFullSizeImage = true;
    },
    getDiscount() {
      return this.offer.discount;
    },
    getOrderData() {
      let sum = 0;
      this.offer.items.forEach((element) => {
        if (element.order > 0) sum += element.order * element.price;
      });
      return sum;
    },
    getImageName(id) {
      let res = "";
      this.offer.items.forEach((element) => {
        if (element.id == id) res = element.images[0];
      });
      return "https://xn--80axep4eb.xn--j1amh/public/images/" + res;
    },
  },
};
</script>

<style scoped lang="sass">
.smalltext
  padding-left: 15px
  color: #8F8E8E
  font-size: 0.7em
  width: 100%

.dividerholder
  padding: 5px
  .dividersolid
    opacity: 0.1

.stringsum
  display: block
  color: #E7E7E7
  padding-right: 5px
  padding-left: 5px
  width: 100%
  font-size: 0.8em
  .large
    font-size: 2em

.orderitemsinfo
  width: 100%
  min-height: 50px

  .itemsum
    display: flex
    color: #E7E7E7
    padding-right: 5px
    padding-left: 5px
    width: 100%
    font-size: 0.8em
    .completesum
      padding-left: 5px
      font-weight: 900
      font-size: 1.3em
      text-align: left
      height: 100%
    .right
      height: 100%
      padding-right: 5px
      font-weight: 400
      font-size: 1.0em
      color: #B1AFAF
      text-decoration: line-through
      text-align: right

  .item
    padding-right: 5px
    padding-left: 15px
    display: flex
    margin-bottom: 5px
    margin-top: 5px
    .itemavatar
      margin-top: 5px
    .code
      color: #F3F0F0
      font-weight: 800
      width: 100%
    .name
      color: #F3F0F0
      font-size: 10px
    .image
      height: 100%
      object-fit: cover

.headerlogo
  margin-left: 15px
  margin-right: 15px
  opacity: 0.7
.countdownholder
  margin-right: 15px
  .headercountdown
    padding: 5px
    font-size: 1.2em
    font-weight: 500
    width: 100%
    text-align: center
    color: #8F8E8E
    
    .headersmalltext
      font-size: 0.5em

.orderitemsinfo

.flyingbutton
  position: fixed
  max-width: 40px
  min-height: 100px
  top: 25px
  left: 5px

.labelHolder
  display: flex
  font-size: 1.1em

.cartlogo
  margin-right: 3px
</style>