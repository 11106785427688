<template>
  <div class="item">
    <vs-card type="1">
      <template #title>
        <h3>{{ item.code }}</h3>
      </template>
      <template #img >
        <img @click="showImage()" :src="getImage()" alt="" />
      </template>
      <template #text>
        <p>
          {{ item.name }}
        </p>
      </template>
      <template #interactions>
        <vs-button success primary>
          <i class="pricetag bx bx-purchase-tag"></i>
          <span class="span">Ваша ціна: {{ getPrice() }} </span>
        </vs-button>
      </template>
      <template #buttons>
        <vs-row>
          <div class="ordercontrol">
            <vs-button :active="true" @click="deductOrder" danger size="xl">
              <span class="span"> – </span>
            </vs-button>
            <div class="textorderholder">
              <div class="ordertext">{{ item.order }} шт.</div>
            </div>
            <vs-button :active="true" @click="addOrder" success size="xl">
              <span class="span"> + </span>
            </vs-button>
          </div>
        </vs-row>
      </template>
    </vs-card>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    discount: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  methods: {
    openNotification(position = null, color, icon, title, text) {
      this.$vs.notification({
        icon,
        color,
        position,
        title: title,
        text: text,
      });
    },
    showImage(){
      this.$emit("showFullImage", this.item);
    },
    addOrder() {
      this.item.order++;
      if(this.item.maxquantity < this.item.order){
        this.openNotification("top-center",
          '#7d33ff',
          `<i class='bx bx-info-circle' ></i>`,
          'Це максимум',
          'ви замовили максимально допустиму кількість товару')
        this.item.order = this.item.maxquantity
      }
    },
    deductOrder() {
      if (this.item.order > 0) {
        this.item.order--;
      }
    },
    roundUp(num, precision) {
      precision = Math.pow(10, precision);
      return Math.ceil(num * precision) / precision;
    },
    getPrice() {
      let currPrice = this.roundUp(
        this.item.price - (this.item.price / 100) * this.discount,
        2
      );
      let pricestr = this.addZeroes(currPrice) + " грн.";
      return pricestr;
    },
    addZeroes(num) {
      if (num == null) num = 0;
      return num.toFixed(Math.max(((num + "").split(".")[1] || "").length, 2));
    },
    getImage() {
      return "https://xn--80axep4eb.xn--j1amh/public/images/" + this.item.images[0];
    },
  },
};
</script>

<style scoped>
.pricetag{
  margin-right:3px;
  font-size: 15px;
}

.span{
  font-size: 15px;
  font-weight: 200;
}

.item {
  margin-bottom: 25px;
  margin-left: 5px;
  margin-right: 5px;
}
.textorderholder {
  display: flex;
  margin-right: 15px;
  margin-left: 15px;
  height: 55px;
  font-size: 1.2em;
  vertical-align: middle;
}
.ordertext {
  align-self: center;
}
.ordercontrol {
  font-weight: 600;
  margin-bottom: 5px;
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>